<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-10 col-md-8"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-2 col-md-4"
        label="ลำดับ"
        v-model="formData.order"
        :validations="[
          {text: 'required!', value: v.formData.order.$dirty && v.formData.order.$error}
        ]">
      </sgv-input-number>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ชื่อ"
        v-model="formData.title"
        :validations="[
          {text: 'required!', value: v.formData.title.$dirty && v.formData.title.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-6"
        label="วันที่สร้าง"
        :value="$date.format(formData.createdAt).displayThai">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6"
        label="วันที่เผยแพร่"
        :value="formData.publishedAt ? $date.format(formData.publishedAt).displayThai : ''">
      </sgv-input-text>
    </div>

    <hr-divider
      class="mt-2"
      :options="tabs"
      select="value"
      v-model="selected">
      <template v-slot="item">
        {{ item.text }}
      </template>
    </hr-divider>

    <DetailFormCover
      v-if="selected === 'cover'"
      :templateType="templateType"
      :assetType="assetType"
      :formData="formData"
      :method="method"
      :v="v">
    </DetailFormCover>

    <DetailFormContent
      v-else-if="selected === 'content'"
      :templateType="templateType"
      :assetType="assetType"
      :formData="formData"
      :method="method"
      :v="v">
    </DetailFormContent>

    <DetailFormSeo
      v-else-if="selected === 'seo'"
      :templateType="templateType"
      :assetType="assetType"
      :formData="formData"
      :method="method"
      :v="v">
    </DetailFormSeo>

    <DetailPriceJunction
      v-else-if="selected === 'price'"
      :templateType="templateType"
      :productType="productType"
      :productId="productId"
      :priceType="priceType"
      :method="method">
    </DetailPriceJunction>

  </div>
</template>

<script>
import DetailFormCover from './DetailFormCover.vue'
import DetailFormContent from './DetailFormContent.vue'
import DetailFormSeo from './DetailFormSeo.vue'
import DetailPriceJunction from './DetailPriceJunction.vue'

export default {
  props: {
    productType: {
      type: String,
      required: true
    },
    productId: {
      type: Number,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    priceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      tabs: [
        {text: 'หน้าปก', value: 'cover'},
        {text: 'เนื้อหา', value: 'content'},
        {text: 'SEO', value: 'seo'},
        {text: 'ราคา', value: 'price'}
      ],
      selected: 'cover'
    }
  },
  components: {
    DetailFormCover,
    DetailFormContent,
    DetailFormSeo,
    DetailPriceJunction,
  }
}
</script>

<style lang="css">
</style>
