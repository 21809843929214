<template>
  <div class="form-row">
    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="Seo Title"
      v-model="formData.seoTitle"
      :validations="[
        {text: 'required!', value: v.formData.seoTitle.$dirty && v.formData.seoTitle.$error}
      ]">
    </sgv-input-text>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="Seo Description"
      autoGrow
      v-model="formData.seoDescription"
      :validations="[
        {text: 'required!', value: v.formData.seoDescription.$dirty && v.formData.seoDescription.$error}
      ]">
    </sgv-input-textarea>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="Seo Keywords"
      v-model="formData.seoKeywords"
      :validations="[
        {text: 'required!', value: v.formData.seoKeywords.$dirty && v.formData.seoKeywords.$error}
      ]">
    </sgv-input-text>

    <AssetFormInput
      label="Seo Image"
      class="col-12"
      maxWidth="250px"
      :disabled="$auth.disabled(method)"
      :assetType="assetType"
      templateType="ChronowoodthAsset"
      v-model="formData.seoImageId"
      :validations="[
        {text: 'required!', value: v.formData.seoImageId.$dirty && v.formData.seoImageId.$error}
      ]">
    </AssetFormInput>
  </div>
</template>

<script>
import AssetFormInput from '@/views/chronowoodth_core/AssetFormInput.vue'

export default {
  props: {
    assetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    AssetFormInput
  }
}
</script>

<style lang="css">
</style>
