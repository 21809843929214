<template>
  <sgv-input-autocomplete
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">
    <template slot-scope="option">
      {{option.code}}
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { LIST_PRICE } from './graph/price'

export default {
  name: 'PriceFormInput',
  props: {
    templateType: {
      type: String,
      required: true
    },
    priceType: {
      type: String,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    oneWay: {
      type: Boolean,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    }
  },
  data () {
    return {
      display: null,
      options: [],
      search: '',
    }
  },
  methods: {
    getDisplay (option) {
      return `${option.code}`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.$emit('change', option)
      this.emitInput(this.optionByKey(option))
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    refetch: debounce(function() {
      this.$apollo.query({
        query: LIST_PRICE(this.templateType),
        variables: {
          priceType: this.priceType,
          q: {
            limit: 10,
            search: this.search
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.options = res.data.prices
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }, 300),
    fetchData (id) {
      this.$apollo.query({
        query: LIST_PRICE(this.templateType),
        variables: {
          priceType: this.priceType,
          q: {
            limit: 1,
            id
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const prices = res.data.prices
        this.options = prices
        this.setDisplay(prices[0])
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }
  },
  watch: {
    search () {
      this.refetch()
    },
    value: {
      handler (value) {
        if (!this.oneWay && value) {
          this.fetchData(value)
        } else if (!value) {
          this.display = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">
</style>
