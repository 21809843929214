import gql from 'graphql-tag'


const listResponse = `
  id type order productId
  priceId price {
    id type code name price dueAt duePrice
    unitId unit {id name}
  }
  dueAt duePrice dueRemark
  isCover isContent
`

export const LIST_PRICE_JUNCTION = (templateType) => gql`query LIST_PRICE_JUNCTION ($productType: String!, $productId: Int!) {
  priceJunctions: list${templateType}PriceJunction (productType: $productType, productId: $productId) {${listResponse}}
}`

const detailResponse = `
  id type order productId priceId
  dueAt duePrice dueRemark
  isCover isContent
`

export const DETAIL_PRICE_JUNCTION = (templateType) => gql`query DETAIL_PRICE_JUNCTION ($productType: String!, $productPriceJunctionId: Int!) {
  priceJunction: detail${templateType}PriceJunction (productType: $productType, productPriceJunctionId: $productPriceJunctionId) {${detailResponse}}
}`

export const CREATE_PRICE_JUNCTION = (templateType) => gql`mutation CREATE_PRICE_JUNCTION ($productType: String!, $productId: Int!, $input: ${templateType}PriceJunctionCreateInput!) {
  createPriceJunction: create${templateType}PriceJunction (productType: $productType, productId: $productId, input: $input) {${detailResponse}}
}`

export const UPDATE_PRICE_JUNCTION = (templateType) => gql`mutation UPDATE_PRICE_JUNCTION ($productType: String!, $productPriceJunctionId: Int!, $input: ${templateType}PriceJunctionUpdateInput!) {
  updatePriceJunction: update${templateType}PriceJunction (productType: $productType, productPriceJunctionId: $productPriceJunctionId, input: $input) {${detailResponse}}
}`

export const DESTROY_PRICE_JUNCTION = (templateType) => gql`mutation DESTROY_PRICE_JUNCTION ($productType: String!, $productPriceJunctionId: Int!) {
  destroyPriceJunction: destroy${templateType}PriceJunction (productType: $productType, productPriceJunctionId: $productPriceJunctionId) {id}
}`