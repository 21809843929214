<template>
  <sgv-table
    :items="priceJunctions"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot="action" v-if="method === 'edit'">
      <DetailPriceJunctionModal
        title="เพิ่ม"
        :templateType="templateType"
        :productType="productType"
        :productId="productId"
        :priceType="priceType"
        :productPriceJunctionId="0"
        @updated="refetch">
        <template v-slot="{toggle}">
          <button type="button" class="btn btn-success" @click="toggle">เพิ่ม</button>
        </template>
      </DetailPriceJunctionModal>
    </template>

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.type">
          <DetailPriceJunctionModal
            :title="item.price.name"
            :templateType="templateType"
            :productType="productType"
            :productId="productId"
            :priceType="priceType"
            :productPriceJunctionId="item.id"
            @updated="refetch">
            <template v-slot="{toggle}">
              {{ item.type }}
              <span class="pointer pr-2" @click="toggle" v-if="method === 'edit'">
                <span class="pl-2">
                  <fa icon="pencil-alt" class="text-warning"></fa>
                </span>
              </span>
              <div>
                <small v-if="item.isCover" class="text-warning pr-1">หน้าปก</small>
                <small v-if="item.isContent" class="text-success pr-1">เนื้อหา</small>
              </div>
            </template>
          </DetailPriceJunctionModal>
        </td>
        <td v-if="hidden.order">
          {{ item.order }}
        </td>
        <td v-if="hidden.price">
          {{ item.price.name }} ({{ item.price.unit.name }})
          <small>
            <div v-if="item.price.dueAt >= new Date()">
              <span class="text-info mr-2">{{ item.price.dueAt | date}}</span>
              <span class="text-danger">{{ item.price.duePrice | comma }}</span>
            </div>
            <div v-else>
              <span>{{ item.price.price | comma }}</span>
            </div>
          </small>
        </td>
        <td v-if="hidden.dueAt">
          {{ item.dueAt | date }}
        </td>
        <td v-if="hidden.duePrice">
          <span class="text-danger">{{ item.duePrice }}</span>
        </td>
        <td v-if="hidden.dueRemark">
          {{ item.dueRemark }}
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import { LIST_PRICE_JUNCTION } from './graph/price'
import DetailPriceJunctionModal from './DetailPriceJunctionModal.vue'


export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    priceType: {
      type: String,
      required: true
    },
    productId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'ประเภท', value: 'type'},
        {text: 'ลำดับ', value: 'order'},
        {text: 'ราคา', value: 'price'},
        {text: 'หมดเขต', value: 'dueAt'},
        {text: 'ราคาพิเศษ', value: 'duePrice'},
        {text: 'หมายเหตุ', value: 'dueRemark'},
      ],
      filter: {
        limit: -1,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['type', 'order', 'price', 'dueAt', 'duePrice', 'dueRemark'],
        column: null,
        search: null,
        toolbar: null,
      },
      priceJunctions: [],
    }
  },
  apollo: {
    priceJunctions: {
      query () {
        return LIST_PRICE_JUNCTION(this.templateType)
      },
      variables () {
        return {
          productType: this.productType,
          productId: this.productId
        }
      },
      fetchPolicy: 'no-cache',
      skip () {
        return this.productId <= 0
      }
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.priceJunctions.refetch()
    },
  },
  components: {
    DetailPriceJunctionModal
  }
}
</script>

<style scoped>

</style>