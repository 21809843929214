var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sgv-table',{attrs:{"items":_vm.priceJunctions,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',[(hidden.type)?_c('td',[_c('DetailPriceJunctionModal',{attrs:{"title":item.price.name,"templateType":_vm.templateType,"productType":_vm.productType,"productId":_vm.productId,"priceType":_vm.priceType,"productPriceJunctionId":item.id},on:{"updated":_vm.refetch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_vm._v(" "+_vm._s(item.type)+" "),(_vm.method === 'edit')?_c('span',{staticClass:"pointer pr-2",on:{"click":toggle}},[_c('span',{staticClass:"pl-2"},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1)]):_vm._e(),_c('div',[(item.isCover)?_c('small',{staticClass:"text-warning pr-1"},[_vm._v("หน้าปก")]):_vm._e(),(item.isContent)?_c('small',{staticClass:"text-success pr-1"},[_vm._v("เนื้อหา")]):_vm._e()])]}}],null,true)})],1):_vm._e(),(hidden.order)?_c('td',[_vm._v(" "+_vm._s(item.order)+" ")]):_vm._e(),(hidden.price)?_c('td',[_vm._v(" "+_vm._s(item.price.name)+" ("+_vm._s(item.price.unit.name)+") "),_c('small',[(item.price.dueAt >= new Date())?_c('div',[_c('span',{staticClass:"text-info mr-2"},[_vm._v(_vm._s(_vm._f("date")(item.price.dueAt)))]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm._f("comma")(item.price.duePrice)))])]):_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("comma")(item.price.price)))])])])]):_vm._e(),(hidden.dueAt)?_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(item.dueAt))+" ")]):_vm._e(),(hidden.duePrice)?_c('td',[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(item.duePrice))])]):_vm._e(),(hidden.dueRemark)?_c('td',[_vm._v(" "+_vm._s(item.dueRemark)+" ")]):_vm._e()])]}}])},[(_vm.method === 'edit')?_c('template',{slot:"action"},[_c('DetailPriceJunctionModal',{attrs:{"title":"เพิ่ม","templateType":_vm.templateType,"productType":_vm.productType,"productId":_vm.productId,"priceType":_vm.priceType,"productPriceJunctionId":0},on:{"updated":_vm.refetch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":toggle}},[_vm._v("เพิ่ม")])]}}],null,false,4176777783)})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }