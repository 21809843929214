<template>
  <div class="form-row">
    <AssetFormArray
      label="Images"
      class="col-12"
      :disabled="$auth.disabled(method)"
      :assetType="assetType"
      :templateType="templateType"
      v-model="formData.imageIds">
    </AssetFormArray>

    <sgv-input-textarea
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="เนื้อหา"
      autoGrow
      v-model="formData.content"
      :validations="[
        {text: 'required!', value: v.formData.content.$dirty && v.formData.content.$error}
      ]">
    </sgv-input-textarea>
  </div>
</template>

<script>
import AssetFormArray from '@/views/chronowoodth_core/AssetFormArray.vue'


export default {
  props: {
    assetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    AssetFormArray
  }
}
</script>

<style lang="css">
</style>
