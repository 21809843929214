import gql from 'graphql-tag'


const listResponse = `
  id type order code title publishedAt
`

export const LIST_PRODUCT = (templateType) => gql`query LIST_PRODUCT ($productType: String!, $q: ${templateType}FilterInput) {
  products: list${templateType} (productType: $productType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type order
  code title coverId remark
  content imageIds
  isHotSale isNewItem
  seoTitle seoDescription seoKeywords seoImageId
  createdAt publishedAt
`

export const DETAIL_PRODUCT = (templateType) => gql`query DETAIL_PRODUCT ($productType: String!, $productId: Int!) {
  product: detail${templateType} (productType: $productType, productId: $productId) {${detailResponse}}
}`

export const CREATE_PRODUCT = (templateType) => gql`mutation CREATE_PRODUCT ($productType: String!, $input: ${templateType}CreateInput!) {
  createProduct: create${templateType} (productType: $productType, input: $input) {${detailResponse}}
}`

export const UPDATE_PRODUCT = (templateType) => gql`mutation UPDATE_PRODUCT ($productType: String!, $productId: Int!, $input: ${templateType}UpdateInput!) {
  updateProduct: update${templateType} (productType: $productType, productId: $productId, input: $input) {${detailResponse}}
}`

export const DESTROY_PRODUCT = (templateType) => gql`mutation DESTROY_PRODUCT ($productType: String!, $productId: Int!) {
  destroyProduct: destroy${templateType} (productType: $productType, productId: $productId) {id}
}`

export const PUBLISH_PRODUCT = (templateType) => gql`mutation PUBLISH_PRODUCT ($productType: String!, $productId: Int!) {
  publishProduct: publish${templateType} (productType: $productType, productId: $productId) {${detailResponse}}
}`

export const UNPUBLISH_PRODUCT = (templateType) => gql`mutation UNPUBLISH_PRODUCT ($productType: String!, $productId: Int!) {
  unpublishProduct: unpublish${templateType} (productType: $productType, productId: $productId) {${detailResponse}}
}`