<template>
  <div class="form-row">
    <AssetFormInput
      label="รูปหน้าปก"
      class="col-12"
      maxWidth="250px"
      :disabled="$auth.disabled(method)"
      :assetType="assetType"
      templateType="ChronowoodthAsset"
      v-model="formData.coverId"
      :validations="[
        {text: 'required!', value: v.formData.coverId.$dirty && v.formData.coverId.$error}
      ]">
    </AssetFormInput>

    <div class="form-group col-12">
      <sgv-input-check
        :disabled="$auth.disabled(method)"
        inline
        label="HotSale"
        v-model="formData.isHotSale">
      </sgv-input-check>
      <sgv-input-check
        :disabled="$auth.disabled(method)"
        inline
        label="NewItem"
        v-model="formData.isNewItem">
      </sgv-input-check>
    </div>

    <sgv-input-text
      :disabled="$auth.disabled(method)"
      class="col-12"
      label="หมายเหตุหน้าปก"
      v-model="formData.remark">
    </sgv-input-text>
  </div>
</template>

<script>
import AssetFormInput from '@/views/chronowoodth_core/AssetFormInput.vue'

export default {
  props: {
    assetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    AssetFormInput
  }
}
</script>

<style lang="css">
</style>
