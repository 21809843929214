var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Seo Title","validations":[
      {text: 'required!', value: _vm.v.formData.seoTitle.$dirty && _vm.v.formData.seoTitle.$error}
    ]},model:{value:(_vm.formData.seoTitle),callback:function ($$v) {_vm.$set(_vm.formData, "seoTitle", $$v)},expression:"formData.seoTitle"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Seo Description","autoGrow":"","validations":[
      {text: 'required!', value: _vm.v.formData.seoDescription.$dirty && _vm.v.formData.seoDescription.$error}
    ]},model:{value:(_vm.formData.seoDescription),callback:function ($$v) {_vm.$set(_vm.formData, "seoDescription", $$v)},expression:"formData.seoDescription"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Seo Keywords","validations":[
      {text: 'required!', value: _vm.v.formData.seoKeywords.$dirty && _vm.v.formData.seoKeywords.$error}
    ]},model:{value:(_vm.formData.seoKeywords),callback:function ($$v) {_vm.$set(_vm.formData, "seoKeywords", $$v)},expression:"formData.seoKeywords"}}),_c('AssetFormInput',{staticClass:"col-12",attrs:{"label":"Seo Image","maxWidth":"250px","disabled":_vm.$auth.disabled(_vm.method),"assetType":_vm.assetType,"templateType":"ChronowoodthAsset","validations":[
      {text: 'required!', value: _vm.v.formData.seoImageId.$dirty && _vm.v.formData.seoImageId.$error}
    ]},model:{value:(_vm.formData.seoImageId),callback:function ($$v) {_vm.$set(_vm.formData, "seoImageId", $$v)},expression:"formData.seoImageId"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }