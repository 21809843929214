<template>
  <span class="">
    <slot v-bind="{toggle}"></slot>

    <b-modal v-model="show" :title="title">
      <div class="form-row">
        <sgv-input-number
          class="col-4"
          label="ลำดับ"
          v-model="formData.order"
          :validations="[
            {text: 'required!', value: $v.formData.order.$dirty && $v.formData.order.$error}
          ]">
        </sgv-input-number>

        <sgv-input-select
          class="col-8"
          label="ประเภทราคา"
          :options="priceTypes"
          select="value"
          v-model="formData.type"
          :validations="[
            {text: 'required!', value: $v.formData.type.$dirty && $v.formData.type.$error}
          ]">
          <template v-slot="item">
            {{ item.text }}
          </template>
        </sgv-input-select>

        <PriceFormInput
          class="col-12"
          label="ราคา"
          select="id"
          templateType="ChronowoodthPrice"
          :priceType="priceType"
          v-model="formData.priceId"
          :validations="[
            {text: 'required!', value: $v.formData.priceId.$dirty && $v.formData.priceId.$error}
          ]">
        </PriceFormInput>

        <sgv-input-date
          class="col-12"
          label="หมดเขต (ถ้ามี)"
          v-model="formData.dueAt">
        </sgv-input-date>

        <sgv-input-currency
          class="col-12"
          label="ราคาพิเศษ (0 = ฟรี, -1 ขอใบเสนอราคา)"
          v-model="formData.duePrice"
          minus>
        </sgv-input-currency>

        <sgv-input-text
          class="col-12"
          label="หมายเหตุ"
          v-model="formData.dueRemark">
        </sgv-input-text>

        <div class="col-12">
          <sgv-input-check
            label="หน้าปก"
            inline
            v-model="formData.isCover">
          </sgv-input-check>
          <sgv-input-check
            label="เนื้อหา"
            inline
            v-model="formData.isContent">
          </sgv-input-check>
        </div>
      </div>

      <template slot="modal-footer">
        <button v-if="productPriceJunctionId === 0" type="button" class="btn btn-success" @click="createOption">เพิ่ม</button>
        <button v-if="productPriceJunctionId > 0" type="button" class="btn btn-warning" @click="updateOption">แก้ไข</button>
        <button v-if="productPriceJunctionId > 0" type="button" class="btn btn-danger" @dblclick="destroyOption">ลบ</button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { 
  DETAIL_PRICE_JUNCTION,
  CREATE_PRICE_JUNCTION,
  UPDATE_PRICE_JUNCTION,
  DESTROY_PRICE_JUNCTION 
} from './graph/price'
import PriceFormInput from '@/views/chronowoodth_core/PriceFormInput.vue'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    priceType: {
      type: String,
      required: true
    },
    productId: {
      type: Number,
      required: true
    },
    productPriceJunctionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      show: false,
      priceTypes: [
        {text: 'สินค้าหลัก', value: 'main'},
        {text: 'บริการเสริม', value: 'additional'},
      ],
      formData: {
        type: 'main',
        order: 0,
        priceId: null,
        dueAt: null,
        duePrice: -1,
        dueRemark: '',
        isCover: true,
        isContent: true
      }
    }
  },
  validations: {
    formData: {
      type: { required },
      order: { required },
      priceId: { required },
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    setFormData (v) {
      this.formData.type = v.type
      this.formData.order = v.order
      this.formData.priceId = v.priceId
      this.formData.dueAt = this.$date.format(v.dueAt).date
      this.formData.duePrice = v.duePrice
      this.formData.dueRemark = v.dueRemark
      this.formData.isCover = v.isCover
      this.formData.isContent = v.isContent
    },
    serializeInput (v) {
      return {
        type: v.type,
        order: v.order,
        priceId: v.priceId,
        dueAt: this.$date.format(v.dueAt).utc,
        duePrice: v.duePrice,
        dueRemark: v.dueRemark,
        isCover: v.isCover,
        isContent: v.isContent,
      }
    },
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PRICE_JUNCTION(this.templateType),
        variables: {
          productType: this.productType,
          productId: this.productId,
          productPriceJunctionId: this.productPriceJunctionId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setFormData(res.data.priceJunction)
      })
      .catch(this.$toasted.global.error)
    },
    createOption () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_PRICE_JUNCTION(this.templateType),
        variables: {
          productType: this.productType,
          productId: this.productId,
          input: this.serializeInput(this.formData)
        }
      }).then(() => {
        this.$emit('updated', null)
        this.show = false
      }).catch(this.$toasted.global.error)
    },
    updateOption () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_PRICE_JUNCTION(this.templateType),
        variables: {
          productType: this.productType,
          productPriceJunctionId: this.productPriceJunctionId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.show = false
      })
      .catch(this.$toasted.global.error)
    },
    destroyOption () {
      this.$apollo.mutate({
        mutation: DESTROY_PRICE_JUNCTION(this.templateType),
        variables: {
          productType: this.productType,
          productPriceJunctionId: this.productPriceJunctionId,
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.show = false
      })
      .catch(this.$toasted.global.error)
    },
  },
  watch: {
    show (v) {
      if (v && this.productPriceJunctionId > 0) this.dataDetail()
    }
  },
  components: {
    PriceFormInput
  }
}
</script>

<style lang="css" scoped>
</style>
